import { getAccessTokenFromAuthCookie, getCookie } from './lib/cookie';

import { AuthSrc } from './lib/interface';
import { checkSession } from './checkSession';
import { fail } from './lib/fail';
import { getConfig } from './config/config';
import { parseAccessTokenPayload } from './lib/accessToken';

export async function loadSessionManager() {
  try {
    const authCookie = getCookie(getConfig().authCookieName);
    const jgAccessToken = getAccessTokenFromAuthCookie(authCookie);
    const jgPayload = parseAccessTokenPayload(jgAccessToken);
    const isPayloadAuthSrcBB = jgPayload?.auth_src === AuthSrc.BB;

    if (!isPayloadAuthSrcBB) {
      // If the session is not linked to BB, we don't need to keep it alive
      return;
    }

    await checkSession();
  } catch (err) {
    fail(err);
  }
}

loadSessionManager();
