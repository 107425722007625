interface BaseErrorConstructor {
  message?: string;
  name: string;
}

export abstract class BaseError extends Error {
  protected constructor({ message, name }: BaseErrorConstructor) {
    super(message);

    this.name = name;
  }
}
