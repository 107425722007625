import Cookies from 'js-cookie';
import { isString } from './typeguards';

export function getCookie(name: string): string {
  const cookie = Cookies.get(name);

  if (!isString(cookie)) {
    throw new Error(`Cookie not found: ${name}`);
  }

  return cookie;
}

export function getAccessTokenFromAuthCookie(cookie: string): string {
  const searchParams = new URLSearchParams(cookie);

  const accessToken = searchParams.get('access_token');

  if (!accessToken) {
    throw new Error(`Access token not found in cookie: ${cookie}`);
  }

  return accessToken;
}
