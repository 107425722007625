export enum Env {
  LOCAL = 'LOCAL',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

export interface ConfigOptions {
  authCookieName: string;
  jgLogoutUrl: string;
}

export interface Config {
  [Env.LOCAL]: ConfigOptions;
  [Env.STAGING]: ConfigOptions;
  [Env.PRODUCTION]: ConfigOptions;
}

export enum AuthSrc {
  BB = 'BB',
  JG = 'JG',
  FB = 'FB',
  TW = 'TW',
}

declare global {
  interface Window {
    loadBBIDSessionManager: () => void;
  }
}
