import { isNumber, isObject, isString } from './typeguards';

export function parseAccessTokenPayload(
  accessToken: string,
): Record<string, unknown> {
  const encodedPayload = accessToken.split('.')[1];

  if (!isString(encodedPayload)) {
    throw new Error('Encoded access token payload is not a string');
  }

  const decodedPayload = decodeBase64EncodedString(encodedPayload);

  const payload = parsePayload(decodedPayload);

  if (!isObject(payload)) {
    throw new Error('Access token payload is not an object');
  }

  return payload;
}

function decodeBase64EncodedString(encodedString: string) {
  return atob(encodedString);
}

function parsePayload(payload: string) {
  try {
    return JSON.parse(payload);
  } catch (err) {
    throw new Error('Unable to parse payload');
  }
}

export function hasPayloadGotExpiry(
  payload: Record<string, unknown>,
): payload is { exp: number } {
  return isNumber(payload?.exp);
}
