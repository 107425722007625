import { ValidationError } from './lib/errors/ValidationError';
import { getBBToken } from './lib/bbAuth';
import { logoutFromJG } from './lib/logout';

const pollTimeout = 60000;
const timeoutIds: number[] = [];

export async function checkSession() {
  setupVisibilityChangeEvent();

  async function setIsPageVisible() {
    if (document.hidden) {
      clearTimeouts();
      return;
    }

    removeVisibilityChangeEvent();
    await checkSession();
  }

  function setupVisibilityChangeEvent() {
    document.addEventListener('visibilitychange', setIsPageVisible, false);
  }

  function removeVisibilityChangeEvent() {
    document.removeEventListener('visibilitychange', setIsPageVisible, false);
  }

  try {
    const validator = (token: string | undefined) => Boolean(token);
    await poll<string | undefined>(getBBToken, validator);
  } catch (err) {
    switch (true) {
      case err instanceof ValidationError:
        removeVisibilityChangeEvent();
        logoutFromJG();
        break;
      default:
        throw err;
    }
  }
}

function clearTimeouts() {
  while (timeoutIds.length) {
    const id = timeoutIds.pop();
    clearTimeout(id);
  }
}

function poll<T>(fn: () => Promise<T>, validate: (result: T) => boolean) {
  // Async promise callback functions will lose any errors thrown and
  // won't reject so we are catching and forcing the reject if that occurs
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async function callback(resolve, reject) {
    let result: T;

    try {
      result = await fn();
    } catch (err) {
      reject(err);
      return;
    }

    if (!validate(result)) {
      reject(new ValidationError());
      return;
    }

    const id = window.setTimeout(callback, pollTimeout, resolve, reject);
    timeoutIds.push(id);
  });
}
